@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./assets/font/Montserrat-Regular.otf');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./assets/font/Montserrat-Medium.otf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./assets/font/Montserrat-Bold.otf');
}

.Montserrat-Regular{
	font-family: 'Montserrat-Regular';
}

.Montserrat-Medium{
	font-family: 'Montserrat-Medium';
}

.Montserrat-BoldMontserrat-Bold{
	font-family: 'Montserrat-Bold';
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: break-word;
  scrollbar-width: none;
  -ms-overflow-style: none;
  font-family: 'Montserrat-Regular';
  color: #000;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.w-infinity{
	width: 100000px;
}

.footer-shadow{
	box-shadow: 0px 2px 100px 0px rgba(133,50,243,0.22);
}

.Gradient{
	color: rgba(66, 149, 218);
	/* background-image: linear-gradient(135deg, #8532F3 0%, #ea983f 100%);
	-webkit-background-clip: text;
	color: transparent; */
}

@keyframes rotate {
    0%{-webkit-transform:rotate(0deg);}
    25%{-webkit-transform:rotate(-90deg);}
    50%{-webkit-transform:rotate(-180deg);}
    75%{-webkit-transform:rotate(-270deg);}
    100%{-webkit-transform:rotate(-360deg);}
}

.run { animation: rotate 60s linear infinite; }

.run .run-shadow{
	position: absolute;
	top: -30%;
	bottom: -30%;
	right: -20%;
	left: -20%;
	background: url(assets/v2/bannerbg.png) center no-repeat;
	background-size: contain;
}

.Airdrop li >div{
	background: rgba(10, 10, 26, 1);
	border: 1px solid rgba(255, 255, 255, 0.40);
	position: relative;
	overflow: hidden;
	border-radius: 20px;
}

.Airdrop li >div::before,.Airdrop li >div::after{
	content: '';
	position: absolute;
	width: 50%;
	height: 50%;
	opacity: 0.5;
	filter: blur(50px);
}

.Airdrop li >div::before{
	top: -50px;
	left: -50px;
	background: #8532F3;
}

.Airdrop li >div::after{
	bottom: -50px;
	right: -50px;
	background: #3242F3;
}

.LAUNCHPAD li>div{
	background: rgba(199,141,255,0.06);
	border: 1px solid rgba(255, 255, 255, 0.40);
	position: relative;
	overflow: hidden;
	border-radius: 20px;
}

.LAUNCHPAD li>div::before{
	content: 'Coming Soon';
	position: absolute;
	right: -15px;
	top: 10%;
	background: rgba(50,66,243,0.3);
	border-radius: 100px;
	padding: 10px 30px 10px 15px;
	font-size: 12px;
}

.LAUNCHPAD li>div .time{
	background: rgba(255,255,255,0.18);
	border-radius: 12px;
}

.WHY li>div{
	min-height: 200px;
	border: 1px solid rgba(255, 255, 255, 0.40);
	position: relative;
	overflow: hidden;
	border-radius: 20px;
	display: flex;
	align-items: flex-start;
	
}

.MeunList li a >div{
	color: #000;
}

.MeunList li a >div:hover{
	/* color: #fff; */
}

.MeunList li a >div::before{
	content:'';
	position:absolute;
	height: 2px;
	/* background: linear-gradient(297deg, #FEA542 0%, #8532F3 100%); */
	background: rgba(66, 149, 218);
	border-radius: 1px;
	width: 50%;
	left:25%;
	bottom:4px;
	display:none;
}

.MeunList li a >div:hover::before{
	display:block;
}

.button{
	transition: all .3s;
}
.button:hover{
	/* transform: scale(1.05); */
	transform: translateY(-5px);
}

.ant-message-notice-content{
	background: linear-gradient(135deg, #8532F3 0%, #FEA542 100%) !important;
}